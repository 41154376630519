import React from "react";
import classes from "./Button.module.scss";

const Button = ({
  onClickHandler,
  children,
  isDisable,
  customClassName,
  customStyle,
}) => {
  return (
    <button
      style={customStyle}
      disabled={isDisable}
      className={`${classes.Button} ${customClassName}`}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
};

export default Button;
