import React, { useState, useEffect, useRef } from "react";
import classes from "./Filters.module.scss";
import Button from "./../Button/Button";
import { capitalizeEachFirstCharInString } from "./../../Helpers";
import FilterByAuthor from "./FilterByAuthor/FilterByAuthor";
import FilterByYear from "./FilterByYear/FilterByYear";
import FilterByLongTitle from "./FilterByLongTitle/FilterByLongTitle";
import { customSelectStyles } from "./Filters.styles";

import axios from "axios";

const Filters = ({
  publications,
  publicationDispatch,
  publicationStaticState,
  publicationsState,
}) => {
  const [query, setQuery] = useState({
    publication_author: null,
    publication_year: null,
    publication_long_title: null,
  });
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [filtersAreActive, setFiltersAreActive] = useState({
    filterByAuthor: false,
    filterByYear: false,
    filterByLongTitle: false,
  });
  const [filtersAreOpen, setFiltersAreOpen] = useState(true);
  const placeholders = {
    filterByAuthor: "Author",
    filterByYear: "Year",
    filterByLongTitle: "Article Name",
  };

  useEffect(() => {
    let oneOfTheFiltersIsActive = false;
    for (let f in filtersAreActive) {
      if (filtersAreActive[f]) {
        oneOfTheFiltersIsActive = true;
        break;
      }
    }

    if (oneOfTheFiltersIsActive) {
      const qp = [];
      const cf = [];
      for (let q in query) {
        if (query[q]) {
          const param = `${encodeURIComponent(q)}=${encodeURIComponent(
            query[q]
          )}`;
          qp.push(param);
          cf.push({
            name: q,
            value: query[q],
          });
          setCurrentFilters(cf);
          setCurrentFilter(query[q]);
        }
      }
      const queryString = qp.length > 0 ? qp.join("&") : "";

      let data = localStorage.getItem("UPDATE_PUBLICATIONS");
      if (data) {
        console.log("OFFLINE-UPDATE_PUBLICATIONS");
        publicationDispatch({
          type: "UPDATE_PUBLICATIONS",
          publications: JSON.parse(data),
        });
      }
      else {
        axios
          .get(
            `https://admin.edwardsdigitallibrary.com/wp-json/publications/search?${queryString}&term_id=${publicationsState.currentChosenProductId}`
          )
          .then((response) => {
            localStorage.setItem('UPDATE_PUBLICATIONS', JSON.stringify(response.data));
            publicationDispatch({
              type: "UPDATE_PUBLICATIONS",
              publications: response.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [query]);

  useEffect(() => {
    if (publicationsState.clearFilters) {
      resetQueryAndCurrentFilters();
      publicationDispatch({
        type: "INIT_FILTERS",
      });
    }
  }, [publicationsState.clearFilters, publicationDispatch]);

  const resetQueryAndCurrentFilters = () => {
    setQuery({
      publication_author: null,
      publication_year: null,
      publication_long_title: null,
    });
    setCurrentFilters([]);
    setCurrentFilter("");
    setFiltersAreActive({
      filterByAuthor: false,
      filterByYear: false,
      filterByLongTitle: false,
    });
  };

  const yearHashTable = {},
    authorsHashTable = {};
  if (publications) {
    publications.forEach((publication) => {
      authorsHashTable[publication.publication_author] =
        publication.publication_author;
    });
    publications.forEach((publication) => {
      const year = new Date(publication.publication_year).getFullYear();
      yearHashTable[year] = year;
    });
  }

  const authors = Object.values(authorsHashTable);
  const years = Object.values(yearHashTable);

  const filterByAuthor = (author) => {
    const authorLc = author.label.toLowerCase();
    setQuery((prevState) => {
      return {
        ...prevState,
        publication_author: authorLc,
      };
    });
    setFiltersAreActive((prevState) => {
      return {
        ...prevState,
        filterByAuthor: true,
      };
    });
  };

  const filterByYear = (year) => {
    setQuery((prevState) => {
      return {
        ...prevState,
        publication_year: year.label,
      };
    });
    setFiltersAreActive((prevState) => {
      return {
        ...prevState,
        filterByYear: true,
      };
    });
  };

  const filterByLongTitle = (typedValue, keyCode) => {
    // filtersRef.current.timer = setTimeout(() => {
    const valuelc = typedValue.toLowerCase();
    setQuery((prevState) => {
      return {
        ...prevState,
        publication_long_title: valuelc,
      };
    });
    // }, 1000);
    setFiltersAreActive((prevState) => {
      return {
        ...prevState,
        filterByLongTitle: true,
      };
    });
  };

  const clearCurrentFilter = (currentFilterName) => {
    const updatedCurrentFilters = currentFilters.filter((cf) => {
      return cf.name !== currentFilterName;
    });

    setQuery((prevQuery) => {
      return {
        ...prevQuery,
        [currentFilterName]: "",
      };
    });

    setCurrentFilters(updatedCurrentFilters);
  };

  const disableOption = (option) => {
    const queryValues = Object.values(query);
    if (queryValues.indexOf(option.value) !== -1) {
      option.isdisabled = true;
    }
    return option.isdisabled;
  };

  const filters = filtersAreOpen ? (
    <div className={classes.Filters}>
      <FilterByLongTitle
        isActive={filtersAreActive.filterByLongTitle}
        filterByLongTitle={filterByLongTitle}
      />
      <FilterByAuthor
        currentValue={currentFilters.publication_author}
        disableOption={disableOption}
        customStyle={customSelectStyles}
        authors={authors}
        onChangeHandler={filterByAuthor}
      />
      <FilterByYear
        currentValue={currentFilters.publication_year}
        disableOption={disableOption}
        customStyle={customSelectStyles}
        years={years}
        onChangeHandler={filterByYear}
      />
      {currentFilters.length > 0 ? (
        <Button
          onClickHandler={() => {
            resetQueryAndCurrentFilters();
            publicationDispatch({
              type: "GET_PUBLICATIONS_INIT",
              publications: publicationStaticState,
              clearFilters: false,
            });
          }}
          customClassName={classes.Filters__clearAllButton}
        >
          X Clear All
        </Button>
      ) : null}
      <div className={classes.Filters__currentFilters}>
        <ul className={classes.Filters__currentFiltersList}>
          {currentFilters.map((currentFilter) => {
            return (
              <li
                className={classes.Filters__currentFiltersItem}
                key={currentFilter.name}
              >
                <Button
                  onClickHandler={() => clearCurrentFilter(currentFilter.name)}
                  customClassName={classes.Filters__currentFiltersDeleteButton}
                >
                  <span>x</span>
                </Button>
                {currentFilter.name === "publication_author"
                  ? capitalizeEachFirstCharInString(currentFilter.value)
                  : currentFilter.value}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;

  return (
    <section className={classes.FiltersSection}>
      <Button
        onClickHandler={() => setFiltersAreOpen((p) => !p)}
        customClassName={classes.FiltersSection__filtersButton}
      >
        Filters{" "}
        {currentFilters.length > 0 ? (
          <span>({currentFilters.length})</span>
        ) : null}
      </Button>
      {filters}
    </section>
  );
};

export default Filters;
