import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PublicationsContextProvider from "./context/PublicationsContext/PublicationsContext";
import { AuthContext } from "./context/AuthContext/AuthContext";
import HomePage from "./pages/HomePage/HomePage";
import Auth from "./pages/Auth/Auth";
import PublicationPage from "./pages/PublicationPage/PublicationPage";
import ReactGA from "react-ga";

//initializing google analytics  by passing the property id to it.
ReactGA.initialize("UA-172839213-1");

function App(props) {
  /**
   * Traking page views
   */
  props.history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  const { state: authState } = React.useContext(AuthContext);
  const guardedRoutes = authState.isLoggedIn ? (
    <Route
      path="/publication/:publication_id/:publication_type"
      component={PublicationPage}
    />
  ) : null;

  return (
    <div className="App">
      <Switch>
        <Route
          path="/"
          exact
          render={() => (
            <PublicationsContextProvider>
              <HomePage isUserLoggedIn={authState.isLoggedIn} />
            </PublicationsContextProvider>
          )}
        />
        <Route
          path="/auth"
          exact
          render={() => <Auth isUserLoggedIn={authState.isLoggedIn} />}
        />
        {guardedRoutes}
        <Route render={() => <h1>Are you lost...?</h1>} />
      </Switch>
    </div>
  );
}

export default withRouter(App);
