import React, { useState } from "react";
import classes from "./EmailForm.module.scss";
import Input from "./../Input/Input";
import Button from "./../Button/Button";

const EmailForm = ({ fileUrl, disclaimer, sendEmailHandler }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const validator = () => {
    let isValid = false;
    const inputHasValue = email.length > 0 && name.length > 0 ? true : false,
      emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      inputHasValidEmail = emailRegex.test(String(email).toLowerCase());
    return (isValid = inputHasValue && inputHasValidEmail);
  };
  const onSendEmail = (e) => {
    e.preventDefault();

    const emailToSend = {
      email,
      name,
      fileUrl,
    };
    sendEmailHandler(emailToSend);
  };

  return (
    <>
      <form onSubmit={onSendEmail} className={classes.EmailForm}>
        <Input
          customClassName={{ input: classes.EmailForm__input }}
          inputPlaceHolder="Your Name"
          inputOnChange={(e) => setName(e.target.value)}
          inputType="text"
          inputValue={name}
        >
          Name
        </Input>
        <Input
          inputPlaceHolder="example@e-mail.com"
          inputOnChange={(e) => setEmail(e.target.value)}
          inputType="email"
          inputValue={email}
        >
          E-mail
        </Input>
        <Button
          customClassName={classes.EmailForm__button}
          isDisable={!validator()}
        >
          Send Email
        </Button>
      </form>
      <p className={classes.EmailForm__disclaimer}>{disclaimer}</p>
    </>
  );
};

export default EmailForm;
//onClickHandler={onLoginHandler} isDisable={!isInputValid}
