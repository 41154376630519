import React, { useState, useEffect } from "react";
import classes from "./Auth.module.scss";
import Signup from "./../../components/Signup/Signup";
import Login from "./../../components/Login/Login";
import Button from "./../../components/Button/Button";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import Checkbox from "../../components/Checkbox/Checkbox";
import Header from "./../../components/Header/Header";
import EdwRedSquare from "./../../components/EdwRedSquare/EdwRedSquare";

// import axios from 'axios';

const Auth = ({ isUserLoggedIn }) => {
  const [hasAccount, setHasAccount] = useState(true);
  const [keepLogin, setKeepLogin] = useState(false);
  //if the user is logged in - redirect to root
  if (isUserLoggedIn) return <Redirect to="/" />;

  const questionTextStyle = {
    letterSpacing: "0.3px",
    fontSize: "15px",
    display: "inline-block",
    margin: "20px 0 0 0 ",
  };
  const questionButtonStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: "bold",
  };

  const LoginOrSignUpComponent = hasAccount ? (
      <Login setKeepLogin={setKeepLogin} keepLogin={keepLogin} />
    ) : (
      <Signup />
    ),
    LoginOrSignUpButton = hasAccount ? (
      <>
        <p style={questionTextStyle}>Don't have an account?</p>
        <Button
          customStyle={questionButtonStyle}
          onClickHandler={() => setHasAccount(false)}
        >
          <strong>Sign Up</strong>
        </Button>
      </>
    ) : (
      <>
        <p style={questionTextStyle}>Already have an account?</p>
        <Button
          customStyle={questionButtonStyle}
          onClickHandler={() => setHasAccount(true)}
        >
          <strong>Log In</strong>
        </Button>
      </>
    );

  return (
    <>
      <Header />
      <div className={classes.Auth}>
        <div className={classes.Auth__container}>
          <EdwRedSquare />
          {LoginOrSignUpComponent}
          {LoginOrSignUpButton}
        </div>
      </div>
    </>
  );
};

export default Auth;
