export const customSelectStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 16,
      color: "#707070",
      textTransform: state.label.length === 1 ? "capitalize" : "none",
      height: state.label.length === 1 ? 26 : 36,
      fontWeight: 100,
      display: "flex",
      alignItems: "center",
      fontWeight: state.label.length === 1 ? "bold" : "100",
      backgroundColor: state.label.length === 1 ? "#DFE7EC" : "#ffffff",
      // backgroundColor: state.isSelected ? "#DFE7EC" : "#ffffff",
      "&:hover": {
        cursor: !state.isDisabled ? "pointer" : "not-allowed",
        backgroundColor: state.label.length === 1 ? "#DFE7EC" : "lightgray",
      },
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      fontSize: 18,
      letterSpacing: 0.36,
      color: "#9B9B9B",
      fontWeight: 100,
    };
  },
  // menu: (provided, state) => {
  //   return {
  //     ...provided,
  //     border:'1px solid red',

  //   };
  // },
  menuList: (provided, state) => {
    return {
      ...provided,

      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: "none",
      boxShadow: "none",
      border: "none",
      height: "100%",
      borderBottom: "1px solid #C4C4C4",
      width: "calc(100% - 20px )",
      marginLeft: "auto",
      backgroundColor: "transparent",
      background: "transparent",
      borderRadius: 0,
      "&:hover": {
        outline: "none",
      },
    };
  },

  container: (provided, state) => {
    // console.log("singleValue", state);
    return {
      ...provided,
      flex: 1,
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      fontSize: 18,
      letterSpacing: 0.36,
      color: "#9B9B9B",
      fontWeight: 100,
    };
  },
  input: (provided, state) => {
    return {
      ...provided,
      fontSize: 18,
      letterSpacing: 0.36,
      color: "#9B9B9B",
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      //     ...provided,
      //   height: 15,
      //   color: '#9B9B9B'
      "& svg": {
        height: 15,
        color: "#9B9B9B",
      },
    };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      padding: 0,
    };
  },
};

//background-color: rgba(2, 96, 147, 0.12);
