import React from "react";
import Button from "../Button/Button";
import classes from "./Popup.module.scss";

const Popup = ({ imgUrl, title, children, popUpClickHandler,buttonInnerHtml,isShowButton=true }) => {
  const button = (isShowButton) ? (
    <Button
    onClickHandler={popUpClickHandler}
    customClassName={classes.Popup__button}
  >
    {buttonInnerHtml||'OK'}
  </Button>
  ):
  null;
  return (
    <div className={classes.Popup}>
      <figure className={classes.Popup__figure}>
        <img className={classes.Popup__img} src={imgUrl} />
      </figure>
      <div className={classes.Popup__content}>
        <h5 className={classes.Popup__title}>{title}</h5>
        <div className={classes.Popup__text}>{children}</div>
        
{button}
      </div>
    </div>
  );
};

export default Popup;
