import React from "react";
import classes from "./Category.module.scss";
const Category = ({ name, id, isActive, filterByCategoryHandler }) => {
  const isActiveClass = isActive ? classes["Category--active"] : "";

  return (
    <li
      onClick={() => filterByCategoryHandler(id,isActive)}
      className={`${classes.Category} ${isActiveClass}`}
    >
      <p className={classes.Category__item}>{name}</p>
    </li>
  );
};

export default Category;
