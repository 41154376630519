import React, { useState, useEffect } from "react";
import classes from "./Categories.module.scss";
import Category from "./Category/Category";
import axios from "axios";
import ReactGA from "react-ga";

const Categories = ({ categories, publicationDispatch }) => {
  const [isActiveCategoryId, setIsActiveCategoryId] = useState(null);

  const getCategoriesByCategoryId = (categoryId, isCategoryActive) => {
    if (isCategoryActive) return;

    const chosenCategory = categories.find((category) => {
      return category.id === categoryId;
    });

    ReactGA.event({
      category: "Filters",
      action: "Filter By Category",
      label: chosenCategory.name,
      transport: "beacon",
    });

    //const url = `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/wp/v2/publications?products=${categoryId}`;
    const url = `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${categoryId}`;
    let data = localStorage.getItem("GET_PUBLICATIONS_INIT" + categoryId);

    if (!navigator.onLine) {
      console.log("OFFLINE-GET_PUBLICATIONS_INIT" + categoryId);
      publicationDispatch({
        type: "GET_PUBLICATIONS_INIT",
        publications: JSON.parse(data),
        clearFilters: true,
      });
      publicationDispatch({
        type: "UPDATE_CURRENT_CHOSEN_PRODUCT_ID",
        chosenProductId: categoryId,
      });

      setIsActiveCategoryId(categoryId);
    } else {
      axios
        .get(url)
        .then((response) => {
          localStorage.setItem(
            "GET_PUBLICATIONS_INIT" + categoryId,
            JSON.stringify(response.data)
          );
          publicationDispatch({
            type: "GET_PUBLICATIONS_INIT",
            publications: response.data,
            clearFilters: true,
          });

          publicationDispatch({
            type: "UPDATE_CURRENT_CHOSEN_PRODUCT_ID",
            chosenProductId: categoryId,
          });

          setIsActiveCategoryId(categoryId);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    if (categories.length > 0) {
      setIsActiveCategoryId(categories[0].id);
    }
  }, [categories]);
  return (
    <section className={classes.CategoriesSection}>
      <div className={classes.Categories}>
        <ul className={classes.Categories__list}>
          {categories.map((category) => (
            <Category
              filterByCategoryHandler={getCategoriesByCategoryId}
              key={category.id}
              name={category.name}
              id={category.id}
              isActive={isActiveCategoryId === category.id}
            />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Categories;
