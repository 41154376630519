import React, { useState,useEffect } from "react";
import classes from './FilterByLongTitle.module.scss';
import Input from "./../../Input/Input";

const FilterByLongTitle = ({ filterByLongTitle,isActive }) => {
  const [value, setValue] = useState("");
  const [longTitleIsValid, setLongTitleIsValid] = useState(false);

  const onChangeHnader = (event) => {
    const value = event.target.value;
    const longTitleRegex = /^[a-zA-Z ]*$/,
      longTitleIsValid = longTitleRegex.test(String(value).toLowerCase());
    if (longTitleIsValid) {
      setValue(value);
      setLongTitleIsValid(true);
    } else {
      alert("character is not allowed ");
    }
  };
  const onKeyupEventHandler = (event) => {
    if (!longTitleIsValid) return;
    const keyCode = event.which || event.keyCode; // Use either which or keyCode, depending on browser support
    const value = event.target.value;
    filterByLongTitle(value, keyCode);
  };

  useEffect(() => {
    if(!isActive){
      setValue('');
    }
  }, [isActive])
 


  return (
    <div className={classes.FilterByLongTitle}>
      <Input
        customClassName={{input:classes.FilterByLongTitle__input}}
        inputPlaceHolder="Enter Article Name"
        inputOnChange={onChangeHnader}
        inputType="text"
        inputValue={value}
        onKeyupEventHandler={onKeyupEventHandler}
      />
    </div>
  );
};

export default FilterByLongTitle;
