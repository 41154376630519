import React from "react";
import classes from './FilterByYear.module.scss';
import Select from "react-select";

const defaultOption = {
  value: "",
  label: "Year",
};
const FilterByYear = ({ years, onChangeHandler,customStyle,disableOption,currentValue }) => {
  const options = years.map((year) => {
    return {
      value: year,
      label: year,
      isdisabled: false
    };
  });

 

  const defaultInputValue = currentValue ? {value:currentValue,label:currentValue} : defaultOption;



  return (
    <div className={classes.FilterByYear}>
      <Select  isOptionDisabled={disableOption} value={defaultInputValue} components={{ IndicatorSeparator: null}}  styles={customStyle} options={options} onChange={onChangeHandler} />
    </div>
  );
};

export default FilterByYear;
