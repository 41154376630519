import React from "react";
import PropTypes from "prop-types";
import classes from "./Input.module.scss";

const Input = ({
  htmlFor,
  children,
  inputValue,
  inputOnChange,
  inputType,
  inputPlaceHolder,
  onKeyupEventHandler,
  customClassName,
}) => {
  return (
    <div className={classes.Input}>
      <label className={customClassName.label} htmlFor={htmlFor}>
        {children}
      </label>
      <input
        value={inputValue}
        type={inputType}
        placeholder={inputPlaceHolder}
        onChange={inputOnChange}
        onKeyUp={onKeyupEventHandler}
        className={customClassName.input}
      />
    </div>
  );
};

Input.propTypes = {
  customClassName: PropTypes.object,
};
Input.defaultProps = {
  customClassName: {
    label: "",
    input: "",
  },
};
export default Input;
