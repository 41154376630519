import React from "react";

const Checkbox = ({
  id,
  labelInnerHtml,
  checkboxOnChange,
  isChecked,
  customClassName,
}) => {
  return (
    <div className="Checkbox">
      <input
        className={customClassName.checkbox}
        checked={isChecked}
        id={id}
        type="checkbox"
        onChange={checkboxOnChange}
      />
      <label className={customClassName.label} htmlFor={id}>
        {labelInnerHtml}
      </label>
    </div>
  );
};

export default Checkbox;
