import axios from "axios";
export const parseParsedPublicationsToHashTable = (publications) => {
  const parsedPublicationsHashTable = {};
  publications.forEach((publication) => {
    parsedPublicationsHashTable[publication.id] =
      publication.attachments_parsed;
  });
  return parsedPublicationsHashTable;
};
export const parseProductsToHashTable = (publications) => {
  const productsToHashTable = {};
  publications.forEach((publication) => {
    productsToHashTable[publication.id] = publication.products;
  });
  return productsToHashTable;
};

export const getDataFromApi = async (apiUrl, cb) => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await axios.get(apiUrl);

    cb(response);
  } catch (error) {
    console.log("getDataFromApi", error);
  }
};

export const parsAuthorsOptionsByAlphabetical = (options) => {
  const hash = {};
  const arr = [];

  options.forEach((option, k) => {
    const firstLtter = option.label.split("")[0];
    if (!hash[firstLtter]) {
      hash[firstLtter] = [option];
    } else {
      hash[firstLtter].push(option);
    }
  });

  for (let i in hash) {
    const nb = {
      value: i,
      label: i,
      isdisabled: true,
    };
    arr.push(nb);
    hash[i].forEach((option) => {
      arr.push(option);
    });
  }

  return arr;
};

export const capitalizeEachFirstCharInString = (str) => {
  const strArr = str.split(" ");

  return strArr
    .map((s) => {
      const firstLetter = s.charAt(0),
        firstLetterCapitalize = firstLetter.toUpperCase(),
        capitalizeString = s.replace(firstLetter, firstLetterCapitalize);
      return capitalizeString;
    })
    .join(" ");
};
