import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import Logo from "./../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { AuthContext } from "./../../context/AuthContext/AuthContext";
import Button from "./../Button/Button";
import Arrow from "./../../assets/images/arrow.svg";
import EdwContainer from "./../EdwContainer/EdwContainer";
import { getDataFromApi } from "./../../Helpers";
import Swal from 'sweetalert2'

import Popup from "../Popup/Popup";
import Modal from "../Modal/Modal";
import * as Platform from 'react-device-detect';

import imgUrl from "../../assets/images/envelope.svg";
const Header = (props) => {

  const { state: authState, dispatch: authDispatch } = React.useContext(
    AuthContext
  );

  const displayExplanationWindow = (Platform.isIOS || ((Platform.isAndroid && !Platform.isChrome))) ? true : false;
  const [appModal, setAppModal] = useState({
    value: localStorage.getItem("installPopup") === null,
    setValue: (newValue) => {
      setAppModal({ appModal, value: newValue });
    },
  });
  const [fromButton, setfromButton] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);

  const [firstTimeK, setFirstTimeK] = useState(true);
  const [firstTimeV, setFirstTimeV] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [fullArr, setFullArr] = useState([]);

  useEffect(() => {
    // Update the document title using the browser API
    console.log(fromButton);
  }, [fromButton]);

  const logOut = () => {
    authDispatch({
      type: "AUTH_LOGOUT_USER",
    });
  };
  // function updateDownloadedNo() {
  //   let existing = localStorage.getItem("downloaded") ? parseInt(localStorage.getItem("downloaded")) : 0;
  //   existing--;
  //   localStorage.setItem("downloaded", existing);
  // }

  function setNoOfDownloads(action) {
    let existing = localStorage.getItem("downloaded") ? localStorage.getItem("downloaded") : 0;
    action === 'add' ? existing++ : existing--;
    localStorage.setItem("downloaded", existing);
  }

  const LogoutButton = authState.isLoggedIn ? (
    <div>
      <Button
        customClassName={classes.Header__deleteButton}
        onClickHandler={() => {
          getPublications().then((response) => {

            if (response && response.length > 0) {
              setFullArr(response);
              setDeleteModal(true);
            }
            else {
              Swal.fire('', 'You don\'t have any file to delete.');
              setDeleteModal(false);
              return;
            }
          });
        }}
      >
        Delete
      </Button>
      <Button
        customClassName={classes.Header__logoutButton}
        onClickHandler={logOut}
      >
        Log out
      </Button>
    </div>
  ) : null;
  const customStylingForContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  window.addEventListener("beforeinstallprompt", function (event) {
    // not show the default browser install app prompt
    event.preventDefault();

    // add the banner here or make it visible
    // �

    // save the event to use it later
    // (it has the important prompt method and userChoice property)
    window.promptEvent = event;
  });

  function addToHomeScreen() {
    // show the install app prompt
    setAppModal(false);
    localStorage.setItem("installPopup", "ok");

    // let products = null;
    // if (!navigator.onLine) {
    //   products = JSON.parse(localStorage.getItem("GET_PRODUCTS_INIT"));
    // }

    if (navigator.onLine) {
      getDataFromApi(
        // `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/wp/v2/products`,
        `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/products`,
        // "http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/products",
        ({ data: products }) => {
          localStorage.setItem("GET_PRODUCTS_INIT", JSON.stringify(products));

          let firstProduct = products[0];
          getDataFromApi(
            `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${firstProduct.id}&per_page=100`,
            // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
            ({ data: publications }) => {
              localStorage.setItem(
                "GET_PUBLICATIONS_INIT" + firstProduct.id,
                JSON.stringify(publications)
              );

              // savePdfFilesToIndexedDB(publications, "s");
              // savePdfFilesToIndexedDB(publications, "p");
              // savePdfFilesToIndexedDB(publications, "f");
            }
          );
          let sProduct = products[1];
          getDataFromApi(
            `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${sProduct.id}&per_page=100`,
            // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
            ({ data: publications }) => {
              localStorage.setItem(
                "GET_PUBLICATIONS_INIT" + sProduct.id,
                JSON.stringify(publications)
              );
              // savePdfFilesToIndexedDB(publications, "s");
              // savePdfFilesToIndexedDB(publications, "p");
              // savePdfFilesToIndexedDB(publications, "f");
            }
          );
          let tProduct = products[2];
          getDataFromApi(
            `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${tProduct.id}&per_page=100`,
            // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
            ({ data: publications }) => {
              localStorage.setItem(
                "GET_PUBLICATIONS_INIT" + tProduct.id,
                JSON.stringify(publications)
              );
              // savePdfFilesToIndexedDB(publications, "s");
              // savePdfFilesToIndexedDB(publications, "p");
              // savePdfFilesToIndexedDB(publications, "f");
            }
          );
        }
      );
    }

    if (window.promptEvent) {
      window.promptEvent.prompt();

      // handle the Decline/Accept choice of the user
      window.promptEvent.userChoice.then(function (choiceResult) {
        // hide the prompt banner here
        // �

        if (choiceResult.outcome === "accepted") {
          localStorage.setItem("appInstaled", 1);
          console.info("mm User accepted the A2HS prompt");
        } else {
          console.info("mm User dismissed the A2HS prompt");
        }
        window.promptEvent = null;
      });
    }
  }
  // function savePdfFilesToIndexedDB(publications, type) {
  //   console.log("save publ");
  //   publications.forEach((element) => {
  //     let link = "";
  //     if (type == "s") {
  //       link = element.attachments.summary.url;
  //     } else if (type == "p") {
  //       link = element.attachments.presentation.url;
  //     } else {
  //       link = element.attachments.full_publication.url;
  //     }
  //     const xhr = new XMLHttpRequest(),
  //       method = "GET",
  //       url = "https://cors-anywhere.herokuapp.com/" + link;

  //     xhr.responseType = "blob";
  //     xhr.open(method, url, true);

  //     xhr.onreadystatechange = function () {
  //       // In local files, status is 0 upon success in Mozilla Firefox
  //       if (xhr.readyState === XMLHttpRequest.DONE) {
  //         var status = xhr.status;
  //         if (status === 0 || (status >= 200 && status < 400)) {
  //           //The request has been completed successfully
  //           var reader = new FileReader();
  //           reader.readAsDataURL(xhr.response);
  //           reader.onloadend = function () {
  //             var base64data = reader.result;

  //             //publicationArr.push(base64data);
  //             let db;
  //             let dbReq = indexedDB.open("myDatabase", 1);
  //             dbReq.onupgradeneeded = function (event) {
  //               // Set the db variable to our database so we can use it!
  //               db = event.target.result;

  //               // Create an object store named notes. Object stores
  //               // in databases are where data are stored.
  //               let publications = db.createObjectStore("publications", {
  //                 autoIncrement: true,
  //               });
  //             };
  //             dbReq.onsuccess = function (event) {
  //               db = event.target.result;
  //               //addPdf(db, base64data, element.id + "_" + type + "_" + element.title.rendered);
  //               addPdf(db, base64data, element.id + "_" + type);
  //               addPdf(db, element.title.rendered, element.id + "_n");
  //             };
  //             dbReq.onerror = function (event) {
  //               console.log("error opening database " + event.target.errorCode);
  //             };
  //           };
  //           //console.log(xhr.response);
  //         } else {
  //           // Oh no! There has been an error with the request!
  //         }
  //       }
  //     };
  //     xhr.send();
  //     //i++;
  //   });
  // }
  // function addPdf(db, message, key) {
  //   // Start a database transaction and get the notes object store
  //   let tx = db.transaction(["publications"], "readwrite");
  //   let store = tx.objectStore("publications");
  //   // Put the sticky note into the object store
  //   let note = { text: message };
  //   store.delete(key);
  //   store.add(note, key);
  //   // Wait for the database transaction to complete
  //   tx.oncomplete = function () {
  //     console.log("stored publication!");
  //   };
  //   tx.onerror = function (event) {
  //     console.log("error storing publication " + event.target.errorCode);
  //   };
  // }
  function removeIds(id) {
    let dids = localStorage.getItem("dids");
    let arrIDS = [];
    if (dids) {
      arrIDS = JSON.parse(dids);
    }
    let found = arrIDS.filter((number) => number == id);
    if (found.length > 0) {
      const index = arrIDS.indexOf(found[0]);
      if (index > -1) {
        arrIDS.splice(index, 1);
      }
    }
    localStorage.setItem("dids", JSON.stringify(arrIDS));
  }
  function deleteFromDb(key) {
    let db;
    let dbReq = indexedDB.open("myDatabase", 1);
    dbReq.onupgradeneeded = function (event) {
      // Set the db variable to our database so we can use it!
      db = event.target.result;

      // Create an object store named notes. Object stores
      // in databases are where data are stored.
      let publications = db.createObjectStore("publications", {
        autoIncrement: true,
      });
    };
    dbReq.onsuccess = function (event) {
      db = event.target.result;
      var transaction = db.transaction(["publications"], "readwrite");
      var objectStore = transaction.objectStore("publications");
      let k = key.split("_");
      let realKey = k[0];
      console.log(realKey + "_f");
      objectStore.delete(realKey + "_f");
      objectStore.delete(realKey + "_n");
      objectStore.delete(realKey + "_p");
      objectStore.delete(realKey + "_s");
      removeIds(realKey);
      Swal.fire(
        '',
        'File deleted succesfully!',
        'success'
      );
      setNoOfDownloads('delete');
      let arr = fullArr.filter((item) => item.key !== key);
      console.log(arr);
      setFullArr(arr);
    };
    dbReq.onerror = function (event) {
      console.log("error opening database " + event.target.errorCode);
    };
  }

  function getPublications() {
    return new Promise(function (resolve) {
      let keys = [];
      let values = [];
      let arr = [];
      getKeys().then((result) => {
        setKeys(result);
        keys = result;
      });

      getValues().then((result) => {
        setKeys(result);
        values = result;
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].indexOf("n") !== -1) {
            arr.push({ key: keys[i], title: values[i] });
          }
        }

        if (arr.length > 0) {
          if (arr[0].title !== undefined) {
            setFullArr(arr);
            setFirstTime(false);
            return resolve(arr);
          }
        }
        else {
          return resolve([]);
        }
      });
      //return resolve([]);
    });
  }

  async function getValues() {
    return new Promise(function (resolve) {
      let db;
      let dbReq = indexedDB.open("myDatabase", 1);
      dbReq.onupgradeneeded = function (event) {
        // Set the db variable to our database so we can use it!
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let publications = db.createObjectStore("publications", {
          autoIncrement: true,
        });
      };
      dbReq.onsuccess = function (event) {
        db = event.target.result;
        var transaction = db.transaction(["publications"], "readwrite");
        var objectStore = transaction.objectStore("publications");
        var request = objectStore.getAllKeys();
        let values = [];
        let requestV = objectStore.getAll();
        // if (firstTimeK) {
        //   request.onsuccess = function (event) {
        //     setKeys(event.target.result);
        //     setFirstTimeK(false);
        //     keys = event.target.result;
        //     return resolve(keys);
        //   };
        // }
        //if (firstTimeV) {
        requestV.onsuccess = function (event) {
          setValues(event.target.result);
          setFirstTimeV(false);
          values = event.target.result;
          return resolve(values);
        };
        //}
      };
      dbReq.onerror = function (event) {
        console.log("error opening database " + event.target.errorCode);
      };
    });
  }

  async function getKeys() {
    return new Promise(function (resolve) {
      let db;
      let dbReq = indexedDB.open("myDatabase", 1);
      dbReq.onupgradeneeded = function (event) {
        // Set the db variable to our database so we can use it!
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let publications = db.createObjectStore("publications", {
          autoIncrement: true,
        });
      };
      dbReq.onsuccess = function (event) {
        db = event.target.result;
        var transaction = db.transaction(["publications"], "readwrite");
        var objectStore = transaction.objectStore("publications");
        var request = objectStore.getAllKeys();
        let keys = [];
        let requestV = objectStore.getAll();
        //if (firstTimeK) {
        request.onsuccess = function (event) {
          setKeys(event.target.result);
          setFirstTimeK(false);
          keys = event.target.result;
          return resolve(keys);
        };
        //}
        // if (firstTimeV) {
        //   requestV.onsuccess = function (event) {
        //     setValues(event.target.result);
        //     setFirstTimeV(false);
        //   };
        // }
      };
      dbReq.onerror = function (event) {
        console.log("error opening database " + event.target.errorCode);
      };
    });
  }

  function showInstallAppPopup() {
    if (!displayExplanationWindow) {
      addToHomeScreen();
    }
    else {
      setAppModal(true);
      setfromButton(true);
    }
  }

  let products = JSON.parse(localStorage.getItem("GET_PRODUCTS_INIT"));
  return (
    <header className={classes.Header}>
      <EdwContainer customStyling={customStylingForContainer}>
        <a href="/">
          <figure className={classes.Header__logoFigure}>
            <img src={Logo} alt="Edwards" className={classes.Header__logoImg} />
          </figure>
        </a>
        {LogoutButton}
      </EdwContainer>
      {!(navigator.standalone || (window.matchMedia('(display-mode: standalone)').matches)) &&
        !localStorage.getItem("appInstaled") && authState.isLoggedIn ? (
          <div className={classes.Header__topGroup} onClick={showInstallAppPopup}>

            <img className={classes.Header__arrowFigure} src={Arrow} />
            <p className={classes.Header__installBtn}> Install App</p>
            <p className={classes.Header__bottomBorder}></p>
          </div>
        ) : !window.navigator.onLine ? (
          <div className={classes.Header__topGroup}>
            <p className={classes.Header__installBtn}> Offline</p>
            <p className={classes.Header__bottomBorderSmall}></p>
          </div>
        ) : null}
      {/* {!window.navigator.onLine && products ? null : !window.navigator.onLine && !products ? (
        <p className={classes.Header__offlineText}>
          You need to connect to internet to get latest data.
        </p>
      ) : null} */}
      {/* !(navigator.standalone || (window.matchMedia('(display-mode: standalone)').matches)) &&
        !localStorage.getItem("appInstaled") && authState.isLoggedIn */}
      {(appModal.value && !(navigator.standalone || (window.matchMedia('(display-mode: standalone)').matches)) &&
        !localStorage.getItem("appInstaled") && authState.isLoggedIn) || (fromButton && appModal) ? (
          <Modal>
            <Popup
              isShowButton={false}
              buttonInnerHtml="Install Edwards Digital Library app"
              title={displayExplanationWindow ? 'Please use "Add to home screen" functionality, from your browser, to install Edwards Digital Library app.' : 'Install Edwards Digital Library app'}
              imgUrl={Logo}
            >
              <Button
                onClickHandler={() => {
                  if (!displayExplanationWindow) {
                    addToHomeScreen();
                  }
                  localStorage.setItem("installPopup", "ok");
                  setAppModal(false);
                  setfromButton(false);
                }}
                customClassName={classes.Header__modalButton}
              >
                Ok
            </Button>
            &nbsp;&nbsp;
            <Button
                onClickHandler={() => {
                  localStorage.setItem("installPopup", "cancel");
                  setAppModal(false);
                  setfromButton(false);
                }}
                customClassName={classes.Header__modalButton}
              >
                Cancel
            </Button>
            </Popup>
          </Modal>
        ) : null
      }
      {
        deleteModal ? (
          <Modal>
            <Popup
              isShowButton={false}
              buttonInnerHtml="Delete data"
              title="Delete data"
              imgUrl={Logo}
            >
              <div className={classes.Header__deletepopupContainer}>
                {fullArr.map((item) => (
                  <div key={item.key} className={classes.Header__tableRow}>
                    <p className={classes.Header__tableCol}>{item.title.text}</p>
                    <Button
                      customClassName={classes.Header__tableColB}
                      onClickHandler={() => {


                        Swal.fire({
                          title: '',
                          text: "Are you sure you want to delete this item?",
                          showCancelButton: true,
                          confirmButtonText: 'Ok'
                        }).then((result) => {
                          if (result.value) {
                            deleteFromDb(item.key);
                          }
                        });

                      }}
                    >
                      X
                </Button>
                  </div>
                ))}
              </div>
              <Button
                onClickHandler={() => {
                  localStorage.setItem("installPopup", "cancel");
                  setDeleteModal(false);
                }}
                customClassName={classes.Header__modalButton}
              >
                Cancel
            </Button>
            </Popup>
          </Modal>
        ) : null
      }
    </header >
  );
};

export default Header;
