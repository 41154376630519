import React from "react";
import classes from "./EdwBanner.module.scss";
import EdwContainer from "./../EdwContainer/EdwContainer";
const EdwBanner = () => {
  return (
    <EdwContainer>
      <div className={classes.EdwBanner}>
        <div className={classes.EdwBanner__imgContainer}>
          <div className={classes.EdwBanner__redSquare}></div>
        </div>
      </div>
    </EdwContainer>
  );
};

export default EdwBanner;
