import React from "react";
import classes from "./FilterByAuthor.module.scss";
import { parsAuthorsOptionsByAlphabetical } from "./../../../Helpers/";
import Select from "react-select";

const defaultOption = {
  value: "",
  label: "Author",
};

const FilterByAuthor = ({
  authors,
  onChangeHandler,
  customStyle,
  disableOption,
  currentValue,
}) => {
  const sortedAuthors = authors.sort();
  const options = sortedAuthors.map((author) => {
    return {
      value: author,
      label: author,
      isdisabled: false,
    };
  });
  const parsedOptions = parsAuthorsOptionsByAlphabetical(options);

  const defaultInputValue = currentValue
    ? { value: currentValue, label: currentValue }
    : defaultOption;

  return (
    <div className={classes.FilterByAuthor}>
      <Select
        isOptionDisabled={disableOption}
        value={defaultInputValue}
        components={{ IndicatorSeparator: null }}
        styles={customStyle}
        options={parsedOptions}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default React.memo(FilterByAuthor);
