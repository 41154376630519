
import React, { useState, useEffect, useRef, Component } from "react";
import { Document, Page } from "react-pdf";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import Button from "./../../../Button/Button";
import Popup from "./../../../Popup/Popup";
import Modal from "./../../../Modal/Modal";
import classes from "./PublicationPdf.module.scss";
import imgUrl from "./../../../../assets/images/envelope.svg";
import EmailForm from "./../../../EmailForm/EmailForm";
import axios from "axios";
import ReactGA from "react-ga";
import Swal from 'sweetalert2'
import { getDataFromApi } from "./../../../../Helpers";
import Logo from "../../../../assets/images/logo.svg";
import * as Platform from 'react-device-detect';
import { Redirect } from "react-router-dom";
import { PDFtoIMG } from 'react-pdf-to-image';

const displayExplanationWindow = (Platform.isIOS || ((Platform.isAndroid && Platform.isChrome))) ? true : false;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PublicationPdf = ({ file, publication_type, pubid }) => {

  const [document, setDocument] = useState({
    numPages: 0,
    pageNumber: 1,
  });



  const Download = require('../../../../assets/images/download.png');
  const DownloadGif = require('../../../../assets/images/download.gif');
  const DownloadDone = require('../../../../assets/icons/tick.png');
  const Images = { Download, DownloadDone, DownloadGif };

  const [wobble, setWobble] = React.useState(0)


  const [downloadImage, setdownloadImage] = useState(Images.Download);

  const [isPresentationModal, setisPresentationModal] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isDocumentHasBeenSentModal, setIsDocumentHasBeenSentModal] = useState(
    false
  );
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const [pdfWidth, setPdfWith] = useState(0);
  const onDocumentLoadSuccess = (doc) => {
    setIsDocumentLoaded(true);
    setDocument({
      ...document,
      numPages: doc.numPages,
    });
  };

  const pdfContainerRef = useRef();

  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);

  const [firstTimeK, setFirstTimeK] = useState(true);
  const [firstTimeV, setFirstTimeV] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [fullArr, setFullArr] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {

    //alert(je);
    const pdfContainerWidth = pdfContainerRef.current.clientWidth;
    setPdfWith(pdfContainerWidth);
    getPublications();
    window.addEventListener("resize", updatePdfContainerWidth);
    return () => {
      window.removeEventListener("resize", updatePdfContainerWidth);
    };
  }, []);

  const updatePdfContainerWidth = () => {
    const pdfContainerWidth = pdfContainerRef.current.clientWidth;
    setPdfWith(pdfContainerWidth);
  };

  const sendPublication = (publicationObject) => {
    const apiUrl = `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/email/send`;

    ReactGA.event({
      category: "Publication",
      action: "Send Email",
      label: publicationObject.fileUrl,
      transport: "beacon",
    });
    axios
      .post(apiUrl, publicationObject)
      .then((response) => {
        setIsEmailModal(false);
        setIsDocumentHasBeenSentModal(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const presentationModalButton =
    publication_type === "presentation" && isDocumentLoaded ? (
      <Button
        onClickHandler={() => setisPresentationModal((p) => !p)}
        customClassName={classes.PublicationPdf__openModalButton}
      />
    ) : null;

  const nextButton =
    publication_type === "presentation" ? (
      <Button
        onClickHandler={() =>
          setDocument((p) => {
            console.log("p", p);
            return {
              numPages: p.numPages,
              pageNumber: p.pageNumber + 1,
            };
          })
        }
        isDisable={document.pageNumber === document.numPages}
        customClassName={`${classes.PublicationPdf__controlButton} ${classes["PublicationPdf__controlButton--next"]}`}
      >
        <span></span>
      </Button>
    ) : null;

  const prevButton =
    publication_type === "presentation" ? (
      <Button
        isDisable={document.pageNumber === 1}
        onClickHandler={() =>
          setDocument((p) => {
            return {
              numPages: p.numPages,
              pageNumber: p.pageNumber - 1,
            };
          })
        }
        customClassName={`${classes.PublicationPdf__controlButton} ${classes["PublicationPdf__controlButton--prev"]}`}
      >
        <span></span>
      </Button>
    ) : null;



  /**
   * Pdf Component - render Modal or regular pdf (publication_type === presentation )
   */
let getPdfImages = (file)=>{
  return(
    file ? (<div>
      <PDFtoIMG file={file}>
      {({ pages }) => {
        if (!pages.length) return 'Loading...';
        return pages.map((page, index) =>
          <img key={index} src={page} style={{ width: "100%", minHeight: "300px", height: "100%" }} ></img>

        );
      }}
  </PDFtoIMG>
    </div>):null
  
);
}
  const itemIsDownloaded = (id) => {
    let dids = localStorage.getItem("dids");
    let arrIDS = [];
    if (dids) {
      arrIDS = JSON.parse(dids);
    }
    let found = arrIDS.filter((number) => number == id);
    return found && found.length > 0;
  }
  const pdfComponent = isPresentationModal ? (
    <Modal>
      <>

        {nextButton}
        {!navigator.onLine ? (
          !Platform.isChrome ? (
            <object style={{ width: pdfWidth, height: "55vh", overflow: "scroll" }}
              data={file}
              type="application/pdf" width={pdfWidth} >
            </object>
            //<iframe src={file} style={{ width: "100%", height: "100%", minHeight: "55vh" }}></iframe>

          ) : (<Document
            className={`${classes.PublicationPdf__document} ${classes.PublicationPdf__documentIsModal}`}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {presentationModalButton}
            <Page width={pdfWidth} pageNumber={document.pageNumber} />
          </Document>)

        ) : (
            <Document
              className={`${classes.PublicationPdf__document} ${classes.PublicationPdf__documentIsModal}`}
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {presentationModalButton}
              <Page width={pdfWidth} pageNumber={document.pageNumber} />
            </Document>

          )}
        {prevButton}
      </>
    </Modal >
  ) : (

      <div>
        {nextButton}
        {!navigator.onLine ?
          (<div>
            <div className={classes.PublicationPdf__rowButtons}>
              <Button
                onClickHandler={() => {
                  Swal.fire({
                    title: '',
                    text: "Are you sure you want to delete this item?",
                    showCancelButton: true,
                    confirmButtonText: 'Ok'
                  }).then((result) => {
                    if (result.value) {
                      deleteFromDb(pubid);
                      //window.location.href = "/";
                    }
                  });
                }
                }
                customClassName={classes.PublicationPdf__emailShareButton}
              >
                Delete
            </Button>
              {navigator.onLine ? (<Button
                onClickHandler={() => setIsEmailModal(true)}
                customClassName={classes.PublicationPdf__emailShareButton}
              >
                Share to e-mail
              </Button>) : null}

            </div>
            {!Platform.isChrome ? (

              // <object style={{ width: pdfWidth, height: "55vh", overflow: "scroll" }}
              //   data={file}
              //   type="application/pdf" width={pdfWidth} >
              // </object>
              //<iframe src={file} style={{ width: "100%", height: "100%", minHeight: "55vh" }}></iframe>
              // <PDFtoIMG file={file}>
              //   {({ pages }) => {
              //     if (!pages.length) return 'Loading...';
              //     return pages.map((page, index) =>
              //       <img key={index} src={page} style={{ width: "100%", minHeight: "300px", height: "100%" }} />

              //     );
              //   }}
              // </PDFtoIMG>
            //   <PDFtoIMG file={file}>
            //       {({ pages }) => {
            //         if (!pages.length) return 'Loading...';
            //         return pages.map((page, index) =>
            //           <img key={index} src={page} style={{ width: "100%", minHeight: "300px", height: "100%" }} ></img>

            //         );
            //       }}
            // </PDFtoIMG>
            getPdfImages(file)



            ) : (<Document
              className={`${classes.PublicationPdf__document} ${classes.PublicationPdf__documentIsModal}`}
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {presentationModalButton}
              {publication_type === "presentation" ?
                <Page width={pdfWidth} pageNumber={document.pageNumber} /> :

                Array.from(
                  new Array(document.numPages),
                  (el, index) => (
                    <Page
                      width={pdfWidth}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                    />
                  ),
                )

              }
              {/* <Page width={pdfWidth} pageNumber={document.pageNumber} /> */}
            </Document>)}
          </div>) : (
            <div><div className={classes.PublicationPdf__rowButtons}>
              {navigator.onLine && !itemIsDownloaded(pubid) ? (

                <Button
                  onClickHandler={() => downloadPdf()}
                  customClassName={classes.PublicationPdf__emailShareButton2}
                ><img className={classes.FirstImage} src={downloadImage} />
                </Button>) : <img className={classes.secondImage} src={DownloadDone} />}

              {navigator.onLine ? (<Button
                onClickHandler={() => setIsEmailModal(true)}
                customClassName={classes.PublicationPdf__emailShareButton}
              >
                Share to e-mail
              </Button>) : null}

            </div>

              <Document
                className={classes.PublicationPdf__document}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
              >

                {presentationModalButton}
                {publication_type === "presentation" ?
                  <Page width={pdfWidth} pageNumber={document.pageNumber} /> :

                  Array.from(
                    new Array(document.numPages),
                    (el, index) => (
                      <Page
                        width={pdfWidth}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                      />
                    ),
                  )

                }
                {/* <Page width={pdfWidth} pageNumber={document.pageNumber} /> */}
              </Document>

              {/* <object style={{ width: "100%", height: "100%" }}
                data={file + "#view=FitH&scrollbar=1&toolbar=1&navpanes=1"}
                type="application/pdf" width={pdfWidth} >
              </object> */}


            </div>)
        }
        {prevButton}
      </div >
    );
  /**
   * Share to EMAIL MODAL  - render Modal or regular pdf (publication_type === presentation )
   */
  const shareToEmailModal = isEmailModal ? (
    <Modal>
      <Popup
        isShowButton={false}
        buttonInnerHtml="Send E-mail"
        title="Enter the e-mail you would like to send this file to"
        imgUrl={imgUrl}
      >
        <EmailForm
          sendEmailHandler={sendPublication}
          disclaimer="WARNING: The name you input will be visible to the receiver."
          fileUrl={file}
        />
        <Button
          onClickHandler={() => setIsEmailModal(false)}
          customClassName={classes.PublicationPdf__closetModalButton}
        ></Button>
      </Popup>
    </Modal>
  ) : null;

  const modalDelete = deleteModal ? (
    <Modal>
      <Popup
        isShowButton={false}
        buttonInnerHtml="Delete data"
        title="Delete data"
        imgUrl={Logo}
      >
        <div className={classes.PublicationPdf__deletepopupContainer}>
          {fullArr.map((item) => (
            <div key={item.key} className={classes.PublicationPdf__tableRow}>
              <p className={classes.PublicationPdf__tableCol}>
                {item.title.text}
              </p>
              <Button
                customClassName={classes.PublicationPdf__tableColB}
                onClickHandler={() => {


                  Swal.fire({
                    title: '',
                    text: "Are you sure you want to delete this item?",
                    showCancelButton: true,
                    confirmButtonText: 'Ok'
                  }).then((result) => {
                    if (result.value) {
                      deleteFromDb(item.key);
                      //window.location.href = "/";

                    }
                  });

                }
                }
              >
                X
              </Button>
            </div>
          ))}
        </div>
        <Button
          onClickHandler={() => {
            localStorage.setItem("installPopup", "cancel");
            setDeleteModal(false);
          }}
          customClassName={classes.PublicationPdf__modalButton}
        >
          Cancel
        </Button>
      </Popup>
    </Modal>
  ) : null;

  /**
   * Document has been sent successfully
   */
  const documentHasBeenSendModal = isDocumentHasBeenSentModal ? (
    <Modal>
      <Popup
        popUpClickHandler={() => setIsDocumentHasBeenSentModal(false)}
        imgUrl={imgUrl}
        title="Your Document Has Been Sent"
      >
        <p>
          You can enjoy reading the rest of the selected content, which can be
          found in the e-mail address provided.
        </p>
      </Popup>
    </Modal>
  ) : null;

  return (
    <div
      className={`${classes.PublicationPdf} ${
        isPresentationModal
          ? classes["PublicationPdf--PresentationModalIsOpen"]
          : ""
        }`}
    >
      {shareToEmailModal}
      {modalDelete}
      {documentHasBeenSendModal}
      {/* <Modal>
        <div
          style={{ border: "1px solid red", height: 100, width: 100 }}
          className="bla"
        ></div>
      </Modal> */}
      {/* <h1>this is PublicationPdf page - type : {publication_type}</h1>
      <p>total num of pages: {document.numPages}</p>
      <p>you are on a page: {document.pageNumber}</p> */}

      <div
        ref={pdfContainerRef}
        id="pdfContainer"
        className={`${classes.PublicationPdf__pdfContainer}`}
      >
        {/* {alert(param)} */}
        {/* {param != "1" ? pdfComponent : (<iframe src={file} style={{ width: "100%", height: "100%", minHeight: "55vh" }}></iframe>)} */}
        {pdfComponent}
      </div>
    </div>
  );
  function getPublications() {
    return new Promise(function (resolve) {
      let keys = [];
      let values = [];
      let arr = [];
      getKeys().then((result) => {
        setKeys(result);
        keys = result;
      });

      getValues().then((result) => {
        setKeys(result);
        values = result;
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].indexOf("n") !== -1) {
            arr.push({ key: keys[i], title: values[i] });
          }
        }

        if (firstTime && arr.length > 0) {
          if (arr[0].title !== undefined) {
            setFullArr(arr);
            setFirstTime(false);
            return resolve(arr);
          }
        }
      });
    });
  }
  function setNoOfDownloads(action) {
    let existing = localStorage.getItem("downloaded") ? localStorage.getItem("downloaded") : 0;
    action === 'add' ? existing++ : existing--;
    localStorage.setItem("downloaded", existing);
  }
  function canDownload() {
    let existing = localStorage.getItem("downloaded") ? localStorage.getItem("downloaded") : 0;
    return existing <= 9;

  }
  function keepDownloadedIds(id) {
    let dids = localStorage.getItem("dids");
    let arrIDS = [];
    if (dids) {
      arrIDS = JSON.parse(dids);
    }
    let found = arrIDS.filter((number) => number == id);
    if (found.length == 0) {
      arrIDS.push(id);
    }
    localStorage.setItem("dids", JSON.stringify(arrIDS));
  }
  function removeIds(id) {
    let dids = localStorage.getItem("dids");
    let arrIDS = [];
    if (dids) {
      arrIDS = JSON.parse(dids);
    }
    let found = arrIDS.filter((number) => number == id);
    if (found.length > 0) {
      const index = arrIDS.indexOf(found[0]);
      if (index > -1) {
        arrIDS.splice(index, 1);
      }
    }
    localStorage.setItem("dids", JSON.stringify(arrIDS));
  }

  function downloadPdf() {
    setdownloadImage(Images.DownloadGif);

    if (!canDownload()) {
      setdownloadImage(Images.Download);
      Swal.fire('', 'You have downloaded too many publications. Delete some publications and then try again').then(() => { setDeleteModal(true); });
      return;
    }
    //if (fullArr.length > 0) {
    //if (fullArr.length < 10) {
    let rightPublication = [];
    getDataFromApi(
      // `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/wp/v2/products`,
      `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/products`,
      // "http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/products",
      ({ data: products }) => {

        localStorage.setItem("GET_PRODUCTS_INIT", JSON.stringify(products));

        let firstProduct = products[0];
        getDataFromApi(
          `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${firstProduct.id}&per_page=100`,
          // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
          ({ data: publications }) => {
            publications.forEach((item) => {
              if (parseInt(item.id) === parseInt(pubid)) {
                rightPublication.push(item);
                console.log(item);
                savePdfFilesToIndexedDB(rightPublication, "s");
                savePdfFilesToIndexedDB(rightPublication, "p");
                savePdfFilesToIndexedDB(rightPublication, "f");
                setNoOfDownloads('add');
                keepDownloadedIds(item.id);
                Swal.fire(
                  '',
                  'File saved succesfully!',
                  'success'
                )
              }
            });
          }
        );
        let sProduct = products[1];
        getDataFromApi(
          `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${sProduct.id}&per_page=100`,
          // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
          ({ data: publications }) => {
            publications.forEach((item) => {
              if (parseInt(item.id) === parseInt(pubid)) {
                rightPublication.push(item);
                console.log(item);
                savePdfFilesToIndexedDB(rightPublication, "s");
                savePdfFilesToIndexedDB(rightPublication, "p");
                savePdfFilesToIndexedDB(rightPublication, "f");
                setNoOfDownloads('add');
                keepDownloadedIds(item.id);
                Swal.fire(
                  '',
                  'File saved succesfully!',
                  'success'
                )
              }
            });
          }
        );
        let tProduct = products[2];
        getDataFromApi(
          `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${tProduct.id}&per_page=100`,
          // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
          ({ data: publications }) => {
            publications.forEach((item) => {
              if (parseInt(item.id) === parseInt(pubid)) {
                rightPublication.push(item);
                console.log(item);
                savePdfFilesToIndexedDB(rightPublication, "s");
                savePdfFilesToIndexedDB(rightPublication, "p");
                savePdfFilesToIndexedDB(rightPublication, "f");
                setNoOfDownloads('add');
                keepDownloadedIds(item.id);
                Swal.fire(
                  '',
                  'File saved succesfully!',
                  'success'
                )
              }
            });
          }
        );
      }
    );

    // } else {

    //   Swal.fire('', 'You have downloaded too many publications. Delete some publications and then try again').then(() => { setDeleteModal(true); });

    // }
    //}
    getPublications().then((response) => {

      console.log(response, response.length);
      if (response.length < 2) {
        let rightPublication = [];
        getDataFromApi(
          // `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/wp/v2/products`,
          `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/products`,
          // "http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/products",
          ({ data: products }) => {
            localStorage.setItem("GET_PRODUCTS_INIT", JSON.stringify(products));

            let firstProduct = products[0];
            getDataFromApi(
              `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${firstProduct.id}&per_page=100`,
              // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
              ({ data: publications }) => {
                publications.forEach((item) => {
                  if (parseInt(item.id) === parseInt(pubid)) {
                    rightPublication.push(item);
                    console.log(item);
                    savePdfFilesToIndexedDB(rightPublication, "s");
                    savePdfFilesToIndexedDB(rightPublication, "p");
                    savePdfFilesToIndexedDB(rightPublication, "f");
                  }
                });
              }
            );
            let sProduct = products[1];
            getDataFromApi(
              `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${sProduct.id}&per_page=100`,
              // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
              ({ data: publications }) => {
                publications.forEach((item) => {
                  if (parseInt(item.id) === parseInt(pubid)) {
                    rightPublication.push(item);
                    console.log(item);
                    savePdfFilesToIndexedDB(rightPublication, "s");
                    savePdfFilesToIndexedDB(rightPublication, "p");
                    savePdfFilesToIndexedDB(rightPublication, "f");
                  }
                });
              }
            );
            let tProduct = products[2];
            getDataFromApi(
              `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${tProduct.id}&per_page=100`,
              // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
              ({ data: publications }) => {
                publications.forEach((item) => {
                  if (parseInt(item.id) === parseInt(pubid)) {
                    rightPublication.push(item);
                    console.log(item);
                    savePdfFilesToIndexedDB(rightPublication, "s");
                    savePdfFilesToIndexedDB(rightPublication, "p");
                    savePdfFilesToIndexedDB(rightPublication, "f");
                  }
                });
              }
            );
          }
        );
      } else {

        Swal.fire('', 'You have downladed too many publications. Delete some publications and then try again').then(() => {
          setDeleteModal(true);
        });

      }
    });
  }

  async function getValues() {
    return new Promise(function (resolve) {
      let db;
      let dbReq = indexedDB.open("myDatabase", 1);
      dbReq.onupgradeneeded = function (event) {
        // Set the db variable to our database so we can use it!
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let publications = db.createObjectStore("publications", {
          autoIncrement: true,
        });
      };
      dbReq.onsuccess = function (event) {
        db = event.target.result;
        var transaction = db.transaction(["publications"], "readwrite");
        var objectStore = transaction.objectStore("publications");
        var request = objectStore.getAllKeys();
        let values = [];
        let requestV = objectStore.getAll();
        // if (firstTimeK) {
        //   request.onsuccess = function (event) {
        //     setKeys(event.target.result);
        //     setFirstTimeK(false);
        //     keys = event.target.result;
        //     return resolve(keys);
        //   };
        // }
        if (firstTimeV) {
          requestV.onsuccess = function (event) {
            setValues(event.target.result);
            setFirstTimeV(false);
            values = event.target.result;
            return resolve(values);
          };
        }
      };
      dbReq.onerror = function (event) {
        console.log("error opening database " + event.target.errorCode);
      };
    });
  }

  async function getKeys() {
    return new Promise(function (resolve) {
      let db;
      let dbReq = indexedDB.open("myDatabase", 1);
      dbReq.onupgradeneeded = function (event) {
        // Set the db variable to our database so we can use it!
        db = event.target.result;

        // Create an object store named notes. Object stores
        // in databases are where data are stored.
        let publications = db.createObjectStore("publications", {
          autoIncrement: true,
        });
      };
      dbReq.onsuccess = function (event) {
        db = event.target.result;
        var transaction = db.transaction(["publications"], "readwrite");
        var objectStore = transaction.objectStore("publications");
        var request = objectStore.getAllKeys();
        let keys = [];
        let requestV = objectStore.getAll();
        if (firstTimeK) {
          request.onsuccess = function (event) {
            setKeys(event.target.result);
            setFirstTimeK(false);
            keys = event.target.result;
            return resolve(keys);
          };
        }
        // if (firstTimeV) {
        //   requestV.onsuccess = function (event) {
        //     setValues(event.target.result);
        //     setFirstTimeV(false);
        //   };
        // }
      };
      dbReq.onerror = function (event) {
        console.log("error opening database " + event.target.errorCode);
      };
    });
  }

  function savePdfFilesToIndexedDB(publications, type) {

    setdownloadImage(Images.DownloadDone);
    console.log("save publ");
    publications.forEach((element) => {
      let link = "";
      if (type == "s") {
        link = element.attachments.summary.url;
      } else if (type == "p") {
        link = element.attachments.presentation.url;
      } else {
        link = element.attachments.full_publication.url;
      }
      const xhr = new XMLHttpRequest(),
        method = "GET",
        url = link;

      xhr.responseType = "blob";
      xhr.open(method, url, true);

      xhr.onreadystatechange = function () {
        // In local files, status is 0 upon success in Mozilla Firefox
        if (xhr.readyState === XMLHttpRequest.DONE) {
          var status = xhr.status;
          if (status === 0 || (status >= 200 && status < 400)) {
            //The request has been completed successfully
            var reader = new FileReader();
            reader.readAsDataURL(xhr.response);
            reader.onloadend = function () {
              var base64data = reader.result;

              //publicationArr.push(base64data);
              let db;
              let dbReq = indexedDB.open("myDatabase", 1);
              dbReq.onupgradeneeded = function (event) {
                // Set the db variable to our database so we can use it!
                db = event.target.result;

                // Create an object store named notes. Object stores
                // in databases are where data are stored.
                let publications = db.createObjectStore("publications", {
                  autoIncrement: true,
                });
              };
              dbReq.onsuccess = function (event) {
                db = event.target.result;
                //addPdf(db, base64data, element.id + "_" + type + "_" + element.title.rendered);
                addPdf(db, base64data, element.id + "_" + type);
                addPdf(db, element.title.rendered, element.id + "_n");
              };
              dbReq.onerror = function (event) {
                console.log("error opening database " + event.target.errorCode);
              };
            };
            //console.log(xhr.response);
          } else {
            // Oh no! There has been an error with the request!
          }
        }
      };
      xhr.send();
      //i++;
    });
  }

  function addPdf(db, message, key) {
    // Start a database transaction and get the notes object store
    let tx = db.transaction(["publications"], "readwrite");
    let store = tx.objectStore("publications");
    // Put the sticky note into the object store
    let note = { text: message };
    store.delete(key);
    store.add(note, key);
    // Wait for the database transaction to complete
    tx.oncomplete = function () {
      console.log("stored publication!");
    };
    tx.onerror = function (event) {
      console.log("error storing publication " + event.target.errorCode);
    };
  }
  function updateDownloadedNo() {
    let existing = localStorage.getItem("downloaded") ? parseInt(localStorage.getItem("downloaded")) : 0;
    existing--;
    localStorage.setItem("downloaded", existing);
  }
  function deleteFromDb(key) {
    let db;
    let dbReq = indexedDB.open("myDatabase", 1);
    dbReq.onupgradeneeded = function (event) {
      // Set the db variable to our database so we can use it!
      db = event.target.result;

      // Create an object store named notes. Object stores
      // in databases are where data are stored.
      let publications = db.createObjectStore("publications", {
        autoIncrement: true,
      });
    };
    dbReq.onsuccess = function (event) {
      db = event.target.result;
      var transaction = db.transaction(["publications"], "readwrite");
      var objectStore = transaction.objectStore("publications");
      let k = key.split("_");
      let realKey = k[0];
      console.log(realKey + "_f");
      objectStore.delete(realKey + "_f");
      objectStore.delete(realKey + "_n");
      objectStore.delete(realKey + "_p");
      objectStore.delete(realKey + "_s");
      removeIds(realKey);
      Swal.fire(
        '',
        'File deleted succesfully!',
        'success'
      );
      setNoOfDownloads('delete');
      let arr = fullArr.filter((item) => item.key !== key);
      console.log(arr);
      setFullArr(arr);
      // Swal.fire(
      //   '',
      //   'File deleted succesfully!',
      //   'success'
      // );
      //updateDownloadedNo();
    };
    dbReq.onerror = function (event) {
      console.log("error opening database " + event.target.errorCode);
    };
  }
};

export default PublicationPdf;
