import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import EdwBanner from "../../components/EdwBanner/EdwBanner";
import EdwContainer from "../../components/EdwContainer/EdwContainer";
import Categories from "../../components/Categories/Categories";
import Filters from "../../components/Filters/Filters";
import Publications from "../../components/publications/publications";
import MainContent from "../../components/MainContent/MainContent";
import { PublicationsContext } from "./../../context/PublicationsContext/PublicationsContext";
import { Redirect } from "react-router-dom";

const HomePage = ({ isUserLoggedIn }) => {
  const {
    state: publicationsState,
    dispatch: publicationDispatch,
  } = React.useContext(PublicationsContext);
  if (!isUserLoggedIn) return <Redirect to="/auth" />;
  const { publications, products, publicationStaticState } = publicationsState;

  return (
    <>
      <Header />
      <EdwBanner />
      <MainContent>
        <EdwContainer>
          <Categories
            publicationDispatch={publicationDispatch}
            categories={products}
          />

          <Filters
            publications={publications}
            publicationStaticState={publicationStaticState}
            publicationDispatch={publicationDispatch}
            publicationsState={publicationsState}
          />
          <Publications publications={publications} />
        </EdwContainer>
      </MainContent>
      <Footer />
    </>
  );
};

export default HomePage;
