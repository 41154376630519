import React, { Component } from "react";
import classes from "./PublicationPage.module.scss";
import { NavLink, Route, Redirect } from "react-router-dom";
import PublicationPdf from "../../components/publications/publication/PublicationPdf/PublicationPdf.js";
import Modal from "./../../components/Modal/Modal";
import Popup from "./../../components/Popup/Popup";
import EmailForm from "./../../components/EmailForm/EmailForm";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MainContent from "../../components/MainContent/MainContent";
import EdwContainer from "../../components/EdwContainer/EdwContainer";
import ReactGA from "react-ga";
let testUrl = null;
let goodURL = window.URL || window.webkitURL;

class EmptyFile extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (<span style={{ color: "red", fontSize: "19px" }}>Please download file first for seeing it offline!</span>);
  }
}

class PublicationComponentOrRedirect extends Component {
  constructor(props) {
    super(props);

    let publicattionTypes = ["summary", "presentation", "full_publication"];

    const isType = publicattionTypes.find((pt) => {
      return this.props.publication_type === pt;
    });

    this.state = {
      publication_type: this.props.publication_type,
      file: this.props.file,
      publicattionTypes: publicattionTypes,
      isType: isType,
    };
  }

  refreshHandler = (file, publication_type) => {
    this.setState({ file: file, publication_type: publication_type });
    this.forceUpdate();
  };

  render() {
    if (this.state.isType) {
      return (
        <PublicationPdf
          file={this.state.file}
          publication_type={this.state.publication_type}
          pubid={this.props.pubid}
        />
      );
    } else {
      //return null;
      return <Redirect to="/404" />;
    }
  }
}

export default class PublicationPage extends Component {

  constructor(props) {
    super(props);
    this.updateFileInState = this.updateFileInState.bind(this);
    this.compRef = React.createRef();
    let { publication_id, publication_type } = this.props.match.params;
    let file = "";
    let parsedFileUrl = null;
    if (navigator.onLine) {
      if (this.props.location && this.props.location.state) {
        if (this.props.location.state.attachments) {
          file = this.props.location.state.attachments[publication_type];
        } else {
          file = JSON.parse(localStorage.getItem("selectedPublication"))
            .attachments[publication_type];
        }
      } else {
        file = JSON.parse(localStorage.getItem("selectedPublication"))
          .attachments[publication_type];
      }
      const fileUrl = file ? file.url : {};
      parsedFileUrl =
        fileUrl.search("https") === -1
          ? fileUrl.replace("http", "https")
          : fileUrl;
    }
    this.state = {
      publication_id: publication_id,
      publication_type: publication_type,
      publication: this.props.location.state
        ? this.props.location.state.publication
        : JSON.parse(localStorage.getItem("selectedPublication")),
      parsedFileUrl: parsedFileUrl,
    };
    if (!navigator.onLine) {
      this.updateFileInState(
        "",
        this.state.publication_id,
        this.state.publication_type
      );
    }
  }
  componentDidMount() { }
  updateFileInState = (val, publication_id, publication_type) => {
    let type = this.state.publication_type;
    if (publication_type != this.state.publication_type) {
      type = publication_type;
    }
    this.getPDFData(this.state.publication_id, type).then(
      (result) => {
        this.setState({ parsedFileUrl: result });
        this.compRef.current.refreshHandler(
          result,
          this.state.publication_type
        );
      }
    );
  };

  getPDFData = async (id, type) => {
    return new Promise(function (resolve) {
      let indexedKey = id + "_" + type.substr(0, 1);
      //var request = indexedDB.open("publications");
      let db;
      let dbReq = indexedDB.open("myDatabase", 1);
      let blobUrl = null;
      dbReq.onsuccess = function (event) {
        db = event.target.result;
        var transaction = db.transaction(["publications"], "readwrite");
        var objectStore = transaction.objectStore("publications");

        var requestFind = objectStore.get(indexedKey);

        requestFind.onsuccess = function (event) {
          const contentType = "application/pdf";

          //let blob = this.b64toBlob(requestFind.result.text.replace(/\s/g, '').replace("data:application/pdf;base64,", ""), contentType);

          if (!requestFind.result) {
            return null;
            //window.location.href = "/404";
          };
          let b64Data = requestFind.result.text.replace(/\s/g, '').replace("data:application/pdf;base64,", "");
          //alertalert("length::" + b64Data.length);
          //return resolve(b64Data);
          const byteCharacters = atob(b64Data);
          const sliceSize = 512;
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });

          blobUrl = goodURL.createObjectURL(blob);
          //this.setState({ parsedFileUrl: blobUrl });
          //this.updateFileInState(blobUrl);
          testUrl = blobUrl;
          //db.close();
          return resolve(blobUrl);
          //this.compRef.current.refreshHandler(blobUrl, type);
        };
        requestFind.onerror = function (event) {
          console.log("error");
        };
      };
    });
    //return blobUrl;
  };

  renderPublicationComponentOrRedirect = (file, publication_type) => {
    return this.state.parsedFileUrl ? (
      // <div>
      // <a href={this.state.parsedFileUrl}>{this.state.parsedFileUrl}</a>
      <PublicationComponentOrRedirect
        file={this.state.parsedFileUrl}
        publication_type={this.state.publication_type}
        ref={this.compRef}
        pubid={this.state.publication_id}
      />
      // </div>

    ) : <EmptyFile></EmptyFile>;
  };
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.parsedFileUrl !== this.state.parsedFileUrl) {
  //     //this.setState({ publication_type: this.state.publication_type });

  //     this.forceUpdate();
  //   }
  // }
  onChosePublicationTypeHandler = (type) => {
    this.setState({ publication_type: type });
    let file = "";
    if (!navigator.onLine) {
      this.updateFileInState("", this.state.publication_id, type);
    } else {
      file = JSON.parse(localStorage.getItem("selectedPublication"))
        .attachments[type];

      const fileUrl = file ? file.url : {};
      file =
        fileUrl.search("https") === -1
          ? fileUrl.replace("http", "https")
          : fileUrl;
    }
    this.compRef.current.refreshHandler(file, type);
    ReactGA.event({
      category: "Publication",
      action: "Publication Type",
      label: `${this.state.publication_type}__${this.state.publication.title.rendered}`,
      transport: "beacon",
    });
  };
  render() {
    return (
      <>
        <Header />
        <EdwContainer>
          <div className={classes.PublicationPage__tabs}>
            <ul className={classes.PublicationPage__tabsList}>
              <NavLink
                onClick={() => {
                  this.onChosePublicationTypeHandler("summary");
                }}
                className={classes.PublicationPage__tab}
                activeClassName={classes.PublicationPage__tabActive}
                to={`/publication/${this.state.publication_id}/summary`}
              >
                Summary
              </NavLink>
              <br />
              <NavLink
                onClick={() => {
                  this.onChosePublicationTypeHandler("presentation");
                }}
                className={classes.PublicationPage__tab}
                activeClassName={classes.PublicationPage__tabActive}
                to={`/publication/${this.state.publication_id}/presentation`}
              >
                Presentation
              </NavLink>
              <br />
              <NavLink
                onClick={() => {
                  this.onChosePublicationTypeHandler("full_publication");
                }}
                className={classes.PublicationPage__tab}
                activeClassName={classes.PublicationPage__tabActive}
                to={`/publication/${this.state.publication_id}/full_publication`}
              >
                Full Publication
              </NavLink>
            </ul>
          </div>
          <MainContent>

            {this.renderPublicationComponentOrRedirect(
              this.state.parsedFileUrl,
              this.state.publication_type
            )}
          </MainContent>
        </EdwContainer>

        <Footer />
      </>
    );
  }
}

//export default PublicationPage;
