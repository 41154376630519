import React from "react";
import Publication from "./publication/Publications";
import classes from "./Publications.module.scss";

const Publications = ({ publications }) => {
  if (publications) {
    return (
      <section className={classes.PublicationsSection}>
        <div className={classes.Publications}>
          {!navigator.onLine ? (<div className={classes.Publications__OfflineMsg}>You are offline. Only this data is available!</div>) : null}
          <ul className={classes.Publications__list}>
            {publications.map((publication) => (
              <Publication key={publication.id} {...publication} />
            ))}
          </ul>
        </div>
      </section>
    );
  }
  return null;
};

export default Publications;
