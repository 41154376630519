import React from "react";
import classes from "./Modal.module.scss";

const Modal = ({ children }) => {
  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__content}>{children}</div>
    </div>
  );
};

export default Modal;
