import React, { useEffect, useReducer } from "react";
// import { useReducer } from "reinspect";
import { getDataFromApi } from "./../../Helpers";
import axios from "axios";

export const PublicationsContext = React.createContext({});

//Initial State
const initialState = {
  publications: [],
  products: [],
  currentChosenProductId: 0,
  clearFilters: false,
  publicationStaticState: [],
};

//Action Creators
const getPublicationsInit = (state, action) => {
  const { publications, clearFilters } = action;
  return {
    ...state,
    publications,
    clearFilters,
    publicationStaticState: publications,
  };
};

const getProductsInit = (state, action) => {
  const { products } = action;
  return {
    ...state,
    products,
  };
};

const updatePublications = (state, action) => {
  const { publications } = action;
  return {
    ...state,
    publications,
  };
};

const updateCurrentChosenProductId = (state, action) => {
  const { chosenProductId } = action;
  return {
    ...state,
    currentChosenProductId: chosenProductId,
  };
};

const updateStaticPublications = (state, action) => {
  const { publications } = action;
  return {
    ...state,
    publicationStaticState: publications,
  };
};

const initFilters = (state, action) => {
  return {
    ...state,
    clearFilters: false,
  };
};
//Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case "GET_PUBLICATIONS_INIT":
      return getPublicationsInit(state, action);
    case "GET_PRODUCTS_INIT":
      return getProductsInit(state, action);
    case "UPDATE_CURRENT_CHOSEN_PRODUCT_ID":
      return updateCurrentChosenProductId(state, action);
    case "UPDATE_PUBLICATIONS":
      return updatePublications(state, action);
    case "INIT_FILTERS":
      return initFilters(state, action);
    case "UPDATE_STATIC_PUBLICATIONS":
      return updateStaticPublications(state, action);
    default:
      throw new Error("Something is wrong, you was not supposed to be here!");
  }
};

const PublicationsContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const [state, dispatch] = useReducer(
  //   reducer,
  //   initialState,
  //   null,
  //   "PublicationsContext"
  // );

  useEffect(() => {
    /**
     * TODO set up an error handler
     */
    //http://localhost/edw_digital_library/edw_wordpress/
    let products = JSON.parse(localStorage.getItem("GET_PRODUCTS_INIT"));

    if (!navigator.onLine && products) {
      console.log("OFFLINE-GET_PRODUCTS_INIT");
      dispatch({
        type: "GET_PRODUCTS_INIT",
        products,
      });
      const firstProduct = products[0];
      dispatch({
        type: "UPDATE_CURRENT_CHOSEN_PRODUCT_ID",
        chosenProductId: firstProduct.id,
      });
      let publications = JSON.parse(
        localStorage.getItem("GET_PUBLICATIONS_INIT" + firstProduct.id)
      );
      if (!navigator.onLine) {
        console.log("OFFLINE-GET_PUBLICATIONS_INIT" + firstProduct.id);
        dispatch({
          type: "GET_PUBLICATIONS_INIT",
          publications,
          clearFilters: false,
        });
      } else {
        getDataFromApi(
          `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${firstProduct.id}&per_page=100`,
          // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
          ({ data: publications }) => {
            localStorage.setItem(
              "GET_PUBLICATIONS_INIT" + firstProduct.id,
              JSON.stringify(publications)
            );
            dispatch({
              type: "GET_PUBLICATIONS_INIT",
              publications,
              clearFilters: false,
            });

            //savePdfFilesToIndexedDB(publications);
          }
        );
      }
    } else {
      getDataFromApi(
        // `${process.env.REACT_APP_EDW_API_ROOT}/wp-json/wp/v2/products`,
        `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/products`,
        // "http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/products",
        ({ data: products }) => {
          localStorage.setItem("GET_PRODUCTS_INIT", JSON.stringify(products));
          dispatch({
            type: "GET_PRODUCTS_INIT",
            products,
          });
          const firstProduct = products[0];
          dispatch({
            type: "UPDATE_CURRENT_CHOSEN_PRODUCT_ID",
            chosenProductId: firstProduct.id,
          });
          let publications = JSON.parse(
            localStorage.getItem("GET_PUBLICATIONS_INIT" + firstProduct.id)
          );
          if (!navigator.onLine) {
            console.log("OFFLINE-GET_PUBLICATIONS_INIT" + firstProduct.id);
            dispatch({
              type: "GET_PUBLICATIONS_INIT",
              publications,
              clearFilters: false,
            });
          } else {
            getDataFromApi(
              `https://admin.edwardsdigitallibrary.com/wp-json/wp/v2/publications?products=${firstProduct.id}&per_page=100`,
              // `http://127.0.0.1:80/edw_digital_library/edw_wordpress/wp-json/wp/v2/publications?products=${firstProduct.id}`,
              ({ data: publications }) => {
                localStorage.setItem(
                  "GET_PUBLICATIONS_INIT" + firstProduct.id,
                  JSON.stringify(publications)
                );
                dispatch({
                  type: "GET_PUBLICATIONS_INIT",
                  publications,
                  clearFilters: false,
                });

              }
            );
          }
        }
      );
    }
  }, []);


  return (
    <PublicationsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </PublicationsContext.Provider>
  );
};

export default PublicationsContextProvider;
