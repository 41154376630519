import React, { useState, useContext } from "react";
import classes from "./Signup.module.scss";
import { withRouter, Link } from "react-router-dom";
import { capitalizeEachFirstCharInString } from "./../../Helpers";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import Checkbox from "./../../components/Checkbox/Checkbox";
import Button from "./../../components/Button/Button";
import axios from "axios";
import Modal from "./../../components/Modal/Modal";
import Popup from "./../../components/Popup/Popup";
import imgUrl from "./../../assets/images/book.svg";
import ReactGA from "react-ga";
const Signup = ({ history }) => {
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const [isRegisteredSuccessfully, setIsRegisteredSuccessfully] = useState(
    false
  );
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isDisplayNameValid, setIsDisplayNameValid] = useState(false);
  const [regUser, setRegUser] = useState({
    userName: "",
    email: "",
    password: "",
    displayName: "",
  });
  const [isAcceptedTermsAndPolicy, setIsAcceptedTermsAndPolicy] = useState(
    false
  );
  const validateEmail = (inputValue) => {
    let isValid = false;
    const inputHasValue = inputValue.length > 0 ? true : false,
      emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      inputHasValidEmail = emailRegex.test(String(inputValue).toLowerCase());
    return (isValid = inputHasValue && inputHasValidEmail);
  };

  const registeredSuccessfullyPopup = isRegisteredSuccessfully ? (
    <Modal>
      <Popup
        popUpClickHandler={() => {
          setIsRegisteredSuccessfully(false);
          history.push("/");
        }}
        imgUrl={imgUrl}
        title={"Request in Process"}
      >
        <p>Thank you for requesting access to Edwards Digital Library.</p>
        <p>
          We are processing your request and will notify you shortly via e-mail
          with your login access.
        </p>
      </Popup>
    </Modal>
  ) : null;
  const onSubmitHandler = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Auth",
      action: "Register Submit",
      transport: "beacon",
    });

    const apiUrl = `https://admin.edwardsdigitallibrary.com/wp-json/register/new/user`;

    console.log(regUser);

    axios
      .post(apiUrl, regUser)
      .then((response) => {
        setIsRegisteredSuccessfully(true);
      })
      .catch((error) => {
        alert(error.response.data.message);
      });
  };

  const emailChangeHandler = (e) => {
    const inputValue = e.target.value;
    const isEmailValid = validateEmail(inputValue);
    setRegUser((prevRegUser) => {
      return {
        ...prevRegUser,
        email: inputValue,
        userName: inputValue,
        password: btoa(inputValue),
      };
    });
    setIsEmailValid(isEmailValid);
  };

  const displayNameChangeHandler = (e) => {
    const inputValue = e.target.value;
    const isDisplayNameValid = inputValue.length > 3;
    setRegUser((prevRegUser) => {
      return {
        ...prevRegUser,
        displayName: capitalizeEachFirstCharInString(inputValue),
      };
    });
    setIsDisplayNameValid(isDisplayNameValid);
  };

  return (
    <div className={classes.Signup}>
      {registeredSuccessfullyPopup}
      <h2 className={classes.Signup__title}>Signup</h2>
      <form onSubmit={onSubmitHandler} className="SignUp__form">
        <div style={{ marginBottom: 35 }} className="formGroup">
          <label className={classes.Signup__inputTextLabel} htmlFor="name">
            Your Name
          </label>
          <input
            onChange={displayNameChangeHandler}
            placeholder={"Your Name"}
            className={classes.Signup__inputText}
            value={regUser.displayName}
            type="text"
            id="name"
          />
        </div>
        <div className="formGroup">
          <label className={classes.Signup__inputTextLabel} htmlFor="email">
            Your Email
          </label>
          <input
            className={classes.Signup__inputText}
            value={regUser.email}
            onChange={emailChangeHandler}
            type="email"
            placeholder="example@e-mail.com"
          />
        </div>

        <Checkbox
          customClassName={{
            checkbox: classes.Signup__inputCheckbox,
            label: classes.Signup__inputCheckboxLabel,
          }}
          isChecked={isAcceptedTermsAndPolicy}
          checkboxOnChange={() => setIsAcceptedTermsAndPolicy((p) => !p)}
          id="serviceAndPolicy"
          labelInnerHtml={
            <span>
              I've read and accepted <span> </span>
              <span style={{ textDecoration: "underline" }}>
                <a
                  href="https://edwardseducation.com/edwardsmasters/legal-terms/"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </span>{" "}
              <span> </span>
              and <span> </span>
              <span style={{ textDecoration: "underline" }}>
                <a
                  href="https://www.edwards.com/gb/legal/privacypolicy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
            </span>
          }
        />
        <Button
          isDisable={
            !isAcceptedTermsAndPolicy || !isEmailValid || !isDisplayNameValid
          }
          customClassName={classes.Signup__button}
        >
          Register
        </Button>
      </form>
    </div>
  );
};

export default withRouter(Signup);
