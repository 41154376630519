import React from "react";
import classes from "./EdwRedSquare.module.scss";

const EdwRedSquare = () => {
  return (
    <div className={classes.EdwRedSquare}>
      <div className={classes.EdwRedSquare__bigSquare}>
        <p className={classes.EdwRedSquare__text}>
          Welcome to the Edwards Digital Library
        </p>
      </div>
      <div className={classes.EdwRedSquare__smallSquare}></div>
    </div>
  );
};

export default EdwRedSquare;
