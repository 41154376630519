import React, { useState, useContext } from "react";
import classes from "./Login.module.scss";
import { AuthContext } from "./../../context/AuthContext/AuthContext";
import Checkbox from "../../components/Checkbox/Checkbox";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import ReactGA from "react-ga";

const Login = ({ setKeepLogin, keepLogin }) => {
  const [loginInputValue, setLoginInputValue] = useState("");
  const [isInputValid, setIsInputValid] = useState(false);
  const { state: authState, dispatch: authDispatch } = useContext(AuthContext);
  const [logUser, setLogUser] = useState({
    email: "",
    username: "",
    password: "",
  });

  const loginOnChange = (event) => {
    const inputValue = event.target.value;
    const isEmailValid = validateEmail(inputValue);
    const logUser = setUserObjectByInputValue(inputValue, isEmailValid);
    setLogUser(logUser);
    setLoginInputValue(inputValue);
    setIsInputValid(isEmailValid);
  };
  const setUserObjectByInputValue = (inputValue, isEmailValid) => {
    if (!isEmailValid) return;
    const logObject = {
      ...logUser,
      email: inputValue,
      username: inputValue,
      password: btoa(inputValue),
    };
    return logObject;
  };

  const onLoginHandler = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Auth",
      action: "Login Submit",
      transport: "beacon",
    });

    const apiUrl = `https://admin.edwardsdigitallibrary.com/wp-json/jwt-auth/v1/token/`;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    };
    axios
      .post(apiUrl, logUser)
      .then((response) => {
        authDispatch({
          type: "AUTH_LOGIN_USER",
          data: { ...response.data, keepLogin },
        });
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Unknown email address. Check again or try your username."
        ) {
          alert("Unknown email address.");
        } else {
          alert(error.response.data.message);
        }
      });
  };

  const validateEmail = (inputValue) => {
    let isValid = false;
    const inputHasValue = inputValue.length > 0 ? true : false,
      emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      inputHasValidEmail = emailRegex.test(String(inputValue).toLowerCase());
    return (isValid = inputHasValue && inputHasValidEmail);
  };

  return (
    <div className={classes.Login}>
      <h2 className={classes.Login__title}>Login</h2>
      <form onSubmit={onLoginHandler}>
        <Input
          customClassName={{
            input: classes.Login__inputText,
            label: classes.Login__inputTextLabel,
          }}
          inputType="email"
          inputValue={loginInputValue}
          inputPlaceHolder="example@email.com"
          inputOnChange={loginOnChange}
        >
          Your Email
        </Input>

        <Checkbox
          customClassName={{
            checkbox: classes.Login__inputCheckbox,
            label: classes.Login__inputCheckboxLabel,
          }}
          isChecked={keepLogin}
          checkboxOnChange={() => setKeepLogin((p) => !p)}
          id="keepLogin"
          labelInnerHtml="Keep me logged in"
        />

        <Button
          customClassName={classes.Login__button}
          isDisable={!isInputValid}
        >
          Log In
        </Button>
      </form>
    </div>
  );
};

export default Login;
