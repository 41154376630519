import React, { useState } from "react";
import classes from "./Publication.module.scss";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import { Document, Page } from "react-pdf";

let pdfUrl = null;
const Publication = ({
  title,
  publication_long_title,
  id,
  attachments,
  publication_author,
  publication_year,
}) => {
  const publication = {
    id,
    publication_long_title,
    title,
    attachments,
  };
  const itemIsDownloaded = (id) => {
    let dids = localStorage.getItem("dids");
    let arrIDS = [];
    if (dids) {
      arrIDS = JSON.parse(dids);
    }
    let found = arrIDS.filter((number) => number == id);
    return found && found.length > 0;
  }

  const savePublicationOnLocalStorage = () => {
    localStorage.setItem("selectedPublication", JSON.stringify(publication));

    ReactGA.event({
      category: "Publication",
      action: "Read More",
      label: publication.title.rendered,
      transport: "beacon",
    });
  };
  const year = new Date(publication_year).getFullYear();
  return (
    navigator.onLine ? (<li className={classes.Publication} style={{ backgroundColor: itemIsDownloaded(id) ? "#f7f7f7" : "#ffffff" }}>
      <div className={classes.Publication__item}>
        <header className={classes.Publication__header}>
          <p className={classes.Publication__longTitle}>
            {publication_long_title}
          </p>
        </header>
        <footer className={classes.Publication__footer}>
          <p className={classes.Publication__author}>{publication_author}</p>
          <p className={classes.Publication__year}>
            {year}
            {itemIsDownloaded(id) ? (
              <div className={classes.Publication__downItem}></div>
            ) : null}
          </p>


          {
            <NavLink
              className={classes.Publication__readMoreBtn}
              onClick={savePublicationOnLocalStorage}
              to={{
                pathname: `/publication/${id}/summary`,
                state: {
                  publication,
                },
              }}
            >
              Read More
      </NavLink>
          }

        </footer>
      </div>

    </li >) : (
        itemIsDownloaded(id) ? (<li className={classes.Publication} style={{ backgroundColor: itemIsDownloaded(id) ? "#f7f7f7" : "#ffffff" }}>
          <div className={classes.Publication__item}>
            <header className={classes.Publication__header}>
              <p className={classes.Publication__longTitle}>
                {publication_long_title}
              </p>
            </header>
            <footer className={classes.Publication__footer}>
              <p className={classes.Publication__author}>{publication_author}</p>
              <p className={classes.Publication__year}>
                {year}
                {itemIsDownloaded(id) ? (
                  <div className={classes.Publication__downItem}></div>
                ) : null}
              </p>


              {
                <NavLink
                  className={classes.Publication__readMoreBtn}
                  onClick={savePublicationOnLocalStorage}
                  to={{
                    pathname: `/publication/${id}/summary`,
                    state: {
                      publication,
                    },
                  }}
                >
                  Read More
              </NavLink>
              }

            </footer>
          </div>

        </li >) : null
      )


  );
};

export default Publication;
