import React, { useEffect, createContext, useReducer } from "react";
// import { useReducer } from "reinspect";
export const AuthContext = createContext({});

const setInitialAuthState = () => {
  let initialState = {
    token: null,
    user_display_name: null,
    user_email: null,
    user_nicename: null,
    isAuthenticated: false,
    isLoggedIn: false,
    keepLogin: false,
  };
  const user =
    JSON.parse(localStorage.getItem("jwb_user")) ||
    JSON.parse(sessionStorage.getItem("jwb_user")) ||
    null;

  if (user) {
    initialState = {
      ...user,
      isAuthenticated: true,
      isLoggedIn: true,
      keepLogin: user.keepLogin,
    };
  }

  return initialState;
};

// Set Initial State
const initialState = setInitialAuthState();

//Actions creators

const loginSuccessfully = (state, action) => {
  const {
    token,
    user_display_name,
    user_email,
    user_nicename,
    keepLogin,
  } = action.data;

  const user = {
    token,
    user_display_name,
    user_email,
    user_nicename,
    keepLogin,
  };
  if (user.keepLogin) {
    localStorage.setItem("jwb_user", JSON.stringify(user));
  } else {
    sessionStorage.setItem("jwb_user", JSON.stringify(user));
  }

  return {
    ...state,
    token,
    user_display_name,
    user_email,
    user_nicename,
    keepLogin,
    isAuthenticated: true,
    isLoggedIn: true,
  };
};
const registeredSuccessfully = (state, action) => {
  return state;
};

const logoutUser = (state, action) => {
  localStorage.removeItem("jwb_user");
  sessionStorage.removeItem("jwb_user");
  return {
    ...state,
    token: null,
    user_display_name: null,
    user_email: null,
    user_nicename: null,
    isAuthenticated: false,
    isLoggedIn: false,
    keepLogin: false,
  };
};
const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_REGISTER_USER":
      return registeredSuccessfully(state, action);
    case "AUTH_LOGIN_USER":
      return loginSuccessfully(state, action);
    case "AUTH_LOGOUT_USER":
      return logoutUser(state, action);
    default:
      throw new Error("Something is wrong, you was not supposed to be here!");
  }
};

const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const [state, dispatch] = useReducer(
  //   reducer,
  //   initialState,
  //   null,
  //   "AuthContext"
  // );

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
